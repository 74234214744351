import React, {useEffect, useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';

const GestoraStep3Component = () => {
  const [formularioValido, setFormularioValido] = useState(false);

  const navigate = useNavigate();

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };


  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/gestora-paso-4');
  };

  useEffect(() => {
    verifyFields();
  }, []);



  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Has participado antes en alguna colectiva? <small className="required">*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    ¿Has participado antes en alguna colectiva? 

                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="participacion" id="participacionSi" value="si"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="participacionSi">
                  Sí
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="participacion" id="participacionNo" value="no"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="participacionNo">
                  No
                </label>
              </div>
              
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Has participado en algún movimiento social a favor de las mujeres? <small className="required">*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                   ¿Has participado en algún movimiento social a favor de las mujeres?

                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="movimiento" id="movimientoSi" value="si"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="movimientoSi">
                  Sí
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="movimiento" id="movimientoNo" value="no"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="movimientoNo">
                  No
                </label>
              </div>
              
            </div>
          </div>
  
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Tienes red(es) social(es) relevante(s) qué compartir compartir de ti o de una organización social a la que pertenezcas?
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                  ¿Tienes red(es) social(es) relevante(s) qué compartir compartir de ti o de una organización social a la que pertenezcas?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <p className="text-gray600">Sólo coloca perfil(es) públicos</p>
            <div className="row">
              <div className="col-lg-6 col-12">
                <label className="form-label label--icon">
                Instagram
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip >
                     Instagram
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
                </label>
                <input
                  type={'text'}
                  className="form-control"
                  id="instagram"
                  placeholder="Ingresa el nombre “@”"
                  onChange={verifyFields}
                />
                <p className="text-gray600 text-12 mb-0">Sólo el nombre de cuenta o después de “/”</p>
              </div>
              <div className="col-lg-6 col-12">
                <label className="form-label label--icon">
                  Facebook u otra
                  <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip >
                      Instagram
                        </Tooltip>
                      }
                    >
                      <span className="material-icons-outlined icon">help_outline</span>
                    </OverlayTrigger>
                  </label>
                  <input
                    type={'text'}
                    className="form-control"
                    id="facebook"
                    placeholder="Ingresa el nombre “@”"
                    onChange={verifyFields}
                  />
                   <p className="text-gray600 text-12 mb-0">Sólo el nombre de cuenta o después de “/”</p>
              </div>
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Qué tanto consideras que sabes sobre el tema del aborto? <small className='required'>*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    ¿Qué tanto consideras que sabes sobre el tema del aborto?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            
              <input
                type={'text'}
                className="form-control"
                id="subject"
                placeholder="Escríbelo aquí"
                onChange={verifyFields}
                />
          </div>
  
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Has vivido lo que es tener un aborto propio? 
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    ¿Has vivido lo que es tener un aborto propio? 
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <textarea className="form-control" id="other" placeholder="Aquí puedes compartirnos alguna experiencia que consideres importante" rows={3}></textarea>
          </div>
  
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
             Siguiente
            </button>
          </div>
       
        </form>
      </div>
    </>
  );
};

export default GestoraStep3Component;
