import React from 'react';

interface Props {
}


const Step6Component: React.FC<Props> = () => {
 
  return (
    <>
     
      <div className="col-12">
      <h3 className="h2 text-400">¿Qué quiere decir esto?</h3>
        <div className="alert alert--simple-check col-md-6 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20"><strong>Revisaremos a detalle tu solicitud para ser gestora.
          Mantente pendiente de tu correo.</strong></span>
        </div>
      
      </div>
      <div className="col-lg-4 col-12">
        <div className="form-row module-top40 form--last">
        <a
             href="/"
             className="btn btn--type1"
           >Ir a mi página de inicio</a>
        </div>
       
        
      </div>
    </>
  );
};

export default Step6Component;
