import React, {useEffect, useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';

const GestoraStep1Component = () => {
  const [formularioValido, setFormularioValido] = useState(false);
  const navigate = useNavigate();

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    verifyFields();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/gestora-paso-2');
  };

  useEffect(() => {
    verifyFields();
  }, []);



  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="name" className="form-label label--icon">
              Nombre y apellido <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Nombre y apellido
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="¿Cómo te llamas?"
            />
          </div>
          <div className="form-row">
            <label htmlFor="email" className="form-label label--icon">
              Correo electrónico <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Esta información es importante para poder tener un medio de
                    contacto durante este procedimiento.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Introduce tu correo"
              onChange={() => {
                verifyFields();
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="phone" className="form-label label--icon">
              Número celular <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Número celular
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              placeholder="Introduce tu número celular"
              onChange={() => {
                verifyFields();
              }}
            />
          </div>
       
  
            <div className="form-row pb-4">
              <div className="form-check check--flex">
                <input
                  className="form-check-input requiredField"
                  type="checkbox"
                  name="acepto-privacidad"
                  id="privacidad"
                  value="privacidad"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="privacidad">
                  He leído y aceptado la <a href="/">Política de Privacidad</a>
                </label>
              </div>
            </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
             Siguiente
            </button>
          </div>
       
        </form>
      </div>
    </>
  );
};

export default GestoraStep1Component;
