import React from 'react';

const FooterShared: React.FC = () => {

  return (
   <footer>
    <div className="container">
      <div className="row">
        <div className="footer__col col-lg-4 col-12">
          <a href="/" className="footer__logo">
            <figure>
              <img src="/assets/images/logo/ola-black.png" alt="OLA Chile" />
            </figure>
          </a>
          <article>
            <p className="text-20">Queremos que te sientas segura y en confianza.</p>
          </article>
          <ul className="quicklinks">
            <li>
              <a href="#" className="text-20">Política de privacidad</a>
            </li>
            <li>
              <a href="#" className="text-20">Términos y condiciones</a>
            </li>
          </ul>
        </div>
        <div className="footer__col col-lg-4 col-12">
         <div className="footer__row">
          <h3 className="h2">Síguenos en</h3>
         </div>
         <ul className="social">
         
          <li>
            <a href="https://www.instagram.com/conlasamigasyenlacasa/" target='_blank' rel="noopener noreferrer">
              <i className="icon icon--instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/conlasamigas" target='_blank' rel="noopener noreferrer">
              <i className="icon icon--twitter"></i>
            </a>
          </li>
         </ul>
        </div>
        <div className="footer__col col-lg-4 col-12">
         <div className="footer__row">
          <h3 className="h2">Contáctanos</h3>
         </div>
         <ul className="contact">
          <li>
            <a href="tel:+56982729131" target='_blank' rel="noopener noreferrer" className="text-20">
              <i className="icon icon--phone"></i>
              <span>(+56) 982 729 131</span>
            </a>
          </li>
          <li>
            <a href="mailto:contacto@conlasamigas.org" target='_blank' rel="noopener noreferrer" className="text-20">
              <i className="icon icon--email"></i>
              <span>contacto@conlasamigas.org</span>
            </a>
          </li>
          
         </ul>
         <ul className="quicklinks quicklink-display mt-4">
            <li>
              <a href="#" className="text-20">Soy Profesional de la Salud</a>
            </li>
            <li>
              <a href="#" className="text-20">Quiero Donar</a>
            </li>
            <li>
              <a href="#" className="text-20">Quiero ser Gestora Comunitaria</a>
            </li>
            <li>
              <a href="#" className="text-20">Quiero Acceder a un Aborto por 3 Causales</a>
            </li>
          </ul>
        </div>
      </div>
    
      <div className="row">
        <div className="text-center col-12">
          <p>Copyright © 2024 OLA. All rights reserved.</p>
        </div>
      </div>
    </div>
   </footer>
  );
};

export default FooterShared;
