import React from 'react';

interface LoaderComponentProps {
  // Define your component props here
}

const LoaderComponent: React.FC<LoaderComponentProps> = () => {
  // Component logic goes here

  return (
    // JSX markup for the component
    <div className="loader-wrapp">
      <span className="loader"></span>
    </div>
  );
};

export default LoaderComponent;