import React, {useEffect, useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import data from '../../../utils/comunas-regiones.json';
import nationData from '../../../utils/nacionalidad.json';

const GestoraStep2Component = () => {
  const [formularioValido, setFormularioValido] = useState(false);
  const [nationality, setNationality] = useState<string>('Chile'); // Estado para la nacionalidad
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [comunas, setComunas] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nation, setNation] = useState(nationData);
  
  const [selectedComuna, setSelectedComuna] = useState<string>(''); 
  const navigate = useNavigate();

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };

  const handleNationalityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNationality(e.target.value);
  };

  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const region = e.target.value;
    setSelectedRegion(region);
    const regionData = data.regiones.find(r => r.region === region);
    setComunas(regionData ? regionData.comunas : []);
    setSelectedComuna(''); // Resetea la comuna seleccionada cuando cambia la región
  };

  const handleComunaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedComuna(e.target.value);
  };
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/gestora-paso-3');
  };

  useEffect(() => {
    verifyFields();
  }, []);



  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="name" className="form-label label--icon">
             Rango de edad <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Rango de edad
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select className="form-select" id="age" onChange={() => {
                verifyFields();
              }}>
              <option value="">Selecciona tu rango de edad</option>
              <option value="18-25">De 18 a 25 años</option>
              <option value="26-30">De 26 a 30 años</option>
              <option value="31-40">De 31 a 40 años</option>
              <option value="41-50">De 41 a 50 años</option>
              <option value="51-60">De 51 a 60 años</option>
            </select>
          </div>
          
          <div className="form-row">
              <label htmlFor="nation" className="form-label label--icon">
                Nacionalidad <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es importante para nosotras generar estadísticas generales sobre quiénes abortan y cuáles son sus países de origen.  Estas estadísticas ayudan a despenalizar socialmente a quienes abortan. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu nacionalidad"
                onChange={handleNationalityChange}
                value={nationality}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {nation.map((country) => (
                  <option key={country.id} value={country.id}>{country.label}</option>
                ))}
              </select>
            </div>
            
            {nationality === "Otros" && (
                <div className="form-row">
                <label htmlFor="age" className="form-label label--icon">
                  Ingresa tu nacionalidad
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="otherNation"
                />
                </div>

            )}
           

            <div className="form-row">
              <label htmlFor="region" className="form-label label--icon">
                Región <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Es importante para nosotras saber en cuál territorio te ubicas, con el objetivo de brindarte información que manejemos respecto a servicios de salud disponibles en tu territorio. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu región"
                onChange={handleRegionChange}
                defaultValue={'default'}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {data.regiones.map((region, index) => (
                  <option key={index} value={region.region}>{region.region}</option>
                ))}
              </select>
            </div>
        
            <div className="form-row">
              <label htmlFor="comuna" className="form-label label--icon">
                Comuna <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                     Es importante para nosotras saber en cuál  territorio te ubicas, con el objetivo de brindarte información que manejemos respecto a servicios de salud disponibles en tu territorio. 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu comuna"
                onChange={handleComunaChange}
                defaultValue={'default'}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {comunas.map((comuna, index) => (
                  <option key={index} value={comuna}>{comuna}</option>
                ))}
              </select>
            </div>
       
  
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido || nationality === '' || selectedRegion === '' || selectedComuna === ''}
            >
             Siguiente
            </button>
          </div>
       
        </form>
      </div>
    </>
  );
};

export default GestoraStep2Component;
