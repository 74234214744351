import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';

// Función de ayuda para la fortaleza de la contraseña
const getPasswordStrength = (password: string) => {
  let strength = 0;
  if (password.length >= 8 && password.length <= 15) strength++;
  if (/\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password)) strength++;
  if (/[A-Z]/.test(password)) strength++;
  return strength;
};


const UpdatePasswordComponent = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisibleTwo, setIsPasswordVisibleTwo] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const navigate = useNavigate();

  const handleToggleClick = () => {
    setIsPasswordVisible(!isPasswordVisible); // Cambia el estado al opuesto de lo que está actualmente
  };
  const handleToggleClickTwo = () => {
    setIsPasswordVisibleTwo(!isPasswordVisibleTwo); // Cambia el estado al opuesto de lo que está actualmente
  };

  const getPasswordStrengthText = (strength: number) => {
    switch (strength) {
      case 1: return 'Débil';
      case 2: return 'Mediana';
      case 3: return 'Fuerte';
      default: return '';
    }
  };

  const validatePassword = (password: string) => {
    setPassword(password);
    const strength = getPasswordStrength(password);
    setPasswordStrength(strength);

    let message = '';
    if (password.length < 8 || password.length > 15) {
      message += 'Escribe entre 8 y 15 caracteres. ';
    }
    if (!/\d/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      message += 'Utiliza letras, números y símbolos. ';
    }
    if (!/[A-Z]/.test(password)) {
      message += 'Utiliza al menos una letra mayúscula. ';
    }
    setAlertMessage(message);
    setIsAlertVisible(message.length > 0);
  };

  const handleConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      setAlertMessage('Las contraseñas no coinciden.');
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 2000);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/iniciar-sesion');
  };

  return (
    <>
       <div className="form-block">
          <form onSubmit={handleSubmit}>
         
            <div className="form-row">
              <label htmlFor="password" className="form-label label--icon">
                Contraseña <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Ingresa tu contraseña nueva contraseña.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-password">
                <button type="button" className="toggle-password" onClick={handleToggleClick}>
                  <span className="material-icons-outlined icon">
                    {isPasswordVisible ? 'visibility_off' : 'visibility'}
                  </span>
                </button>
                <input
                  type={isPasswordVisible ? 'text' : 'password'}
                  className="form-control"
                  id="password"
                  placeholder="Ingresa tu contraseña"
                  value={password}
                  onChange={(e) => validatePassword(e.target.value)}
                />
              </div>
              <div className="password-rules">
                <ul>
                  <li>Escribe entre 8 y 15 caracteres</li>
                  <li>Utiliza letras, números y símbolos</li>
                  <li>Utiliza al menos una letra mayúscula </li>
                </ul>
              </div>
            
            </div>
            <div className="form-row">
                <small>{getPasswordStrengthText(passwordStrength)}</small>
                <div className="password-strength">
                  <div className="password-strength__bar" style={{ width: `${passwordStrength * 33}%`, backgroundColor: passwordStrength === 2 ? '#FFAB48' : passwordStrength === 1 ? '#EBE18C' : '#46BC7C' }}>

                  </div>
                </div>
              </div>
            <div className="form-row">
              <label htmlFor="password" className={`form-label label--icon ${isAlertVisible ? "error" : ""}`}>
               Confirmación de Contraseña <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Confirma tu contraseña nueva.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <div className="form-password">
                <button type="button" className="toggle-password" onClick={handleToggleClickTwo}>
                  <span className="material-icons-outlined icon">
                    {isPasswordVisibleTwo ? 'visibility_off' : 'visibility'}
                  </span>
                </button>
                <input
                  type={isPasswordVisibleTwo ? 'text' : 'password'}
                  className={`form-control ${isAlertVisible ? "error" : ""}`}
                  id="password"
                  placeholder="Ingresa tu contraseña"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPassword(e.target.value)}
                />
              </div>
              {isAlertVisible &&
                <Alert variant='danger' className="alert--small mt-3">
                  {alertMessage}
                </Alert>
              }
             
            </div>
            <div className="form-row form--last">
              <button
                type="submit"
                className="btn btn--type1"
                disabled={password !== confirmPassword || passwordStrength < 2}
              >Entrar</button>
            </div>
            
          </form>
        </div>
    </>
  );
}

export default UpdatePasswordComponent;