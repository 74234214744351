import React, {useEffect, useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';

const GestoraStep5Component = () => {
  const [formularioValido, setFormularioValido] = useState(false);

  const navigate = useNavigate();

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };


  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/gestora-proceso');
  };

  useEffect(() => {
    verifyFields();
  }, []);



  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Por qué te consideras la persona correcta para ser gestora comunitaria? <small className='required'>*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                   ¿Por qué te consideras la persona correcta para ser gestora comunitaria?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            
            <textarea className="form-control requiredField" id="other1" placeholder="Escribe aquí tus razones" rows={3}></textarea>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Por qué te interesa ser gestora comunitaria? <small className='required'>*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                   ¿Por qué te interesa ser gestora comunitaria?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            
            <textarea className="form-control requiredField" id="other2" placeholder="Cuéntanos aquí el por qué de manera concisa" rows={3}></textarea>
          </div>

          <div className="form-row">
            <label htmlFor="name" className="form-label label--icon">
             ¿Cómo te enteraste de OLA? <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    ¿Cómo te enteraste de OLA?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select className="form-select" id="source" onChange={() => {
                verifyFields();
              }}>
              <option value="">Selecciona una de las opciones</option>
              <option value="instagram">Instagram</option>
              <option value="facebook">Facebook</option>
              <option value="google">Google</option>
              <option value="otros">Otros</option>
            </select>
          </div>

          <div className="form-row">
            <label className="form-label label--icon">
            ¿Tienes alguna pregunta o comentario que nos quieras hacer?
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                   ¿Tienes alguna pregunta o comentario que nos quieras hacer?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            
            <textarea className="form-control" id="other3" placeholder="Cuéntanos aquí el por qué de manera concisa" rows={3}></textarea>
          </div>
         
          
         
  
        
  
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
             Siguiente
            </button>
          </div>
       
        </form>
      </div>
    </>
  );
};

export default GestoraStep5Component;
