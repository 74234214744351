import React, {useState} from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../Loader/loader.component';

interface Props {
  // Define your component props here
}

const LoginComponent: React.FC<Props> = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleToggleClick = () => {
    setIsPasswordVisible(!isPasswordVisible); // Cambia el estado al opuesto de lo que está actualmente
  };

  const validateForm = () => {
    const emailIsValid = email.includes('@');
    const passwordIsValid = password.length > 6;

    setIsEmailValid(emailIsValid);
    setIsPasswordValid(passwordIsValid);

    return emailIsValid && passwordIsValid;
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsEmailValid(true); // Restablece la alerta de validación para el correo electrónico
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setIsPasswordValid(true); // Restablece la alerta de validación para la contraseña
  };


  

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm()) {
      navigate('/');
    }
  };

  const isSubmitDisabled = !email || !password || loading;

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
       <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label
            htmlFor="email"
            className={`form-label label--icon ${
              !isEmailValid ? 'error' : ''
            }`}
          >
            Correo electrónico
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>Ingresa un correo electrónico válido.</Tooltip>
              }
            >
              <span className="material-icons-outlined icon">
                help_outline
              </span>
            </OverlayTrigger>
          </label>
          <input
              type="email"
              className={`form-control requiredField ${!isEmailValid ? 'error' : ''}`}
              id="email"
              placeholder="Ingresa tu correo electrónico"
              value={email}
              onChange={handleEmailChange}
            />
            {!isEmailValid && (
              <Alert variant="danger" className="mt-3 alert--small">
                El correo electrónico es incorrecto.
              </Alert>
            )}
        </div>
        <div className="form-row">
            <label
              htmlFor="password"
              className={`form-label label--icon ${
                !isPasswordValid ? 'error' : ''
              }`}
            >
              Contraseña
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Ingresa tu contraseña que creaste para tu cuenta.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-password">
              <button
                type="button"
                className="toggle-password"
                onClick={handleToggleClick}
              >
                <span className="material-icons-outlined icon">
                  {isPasswordVisible ? 'visibility_off' : 'visibility'}
                </span>
              </button>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                className={`form-control requiredField ${!isPasswordValid ? 'error' : ''}`}
                id="password"
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            {!isPasswordValid && (
              <Alert variant="danger" className="mt-3 alert--small">
                La contraseña es incorrecta.
              </Alert>
            )}
          </div>
          <div className="form-row form--last">
            <button type="submit" className="btn btn--type1" disabled={isSubmitDisabled}>
              Entrar
            </button>
          </div>
          <div className="form-row form--last">
            <a href="/crear-cuenta" className="btn btn--type2">
              Registrarme
            </a>
          </div>
          <div className="form-row form--last">
            <a href="/recuperar-contrasena" className="btn--simple">
              Olvidé mi contraseña
            </a>
          </div>
       </form>
    </div>
  );
};

export default LoginComponent;