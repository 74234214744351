import React, {useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';


const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();

  const checkEmailExists = async (email: string): Promise<boolean> => {
    console.log(email);
    // Devuelve true si el correo existe, false en caso contrario.
    return false;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const exists = await checkEmailExists(email);
    setShowAlert(!exists);
    navigate('/actualizar-contrasena');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (showAlert) {
      setShowAlert(false);
    }
  };

  return (
    <>
       <div className="form-block">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label htmlFor="email" className={`form-label label--icon ${showAlert ? "error" : ""}`}
                >
                Correo electrónico 
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Ingresa el correo electrónico con el que te diste de alta.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <input
                type="email"
                className={`form-control ${showAlert ? "error" : ""}`}
                id="email"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                onChange={handleEmailChange}
              />
              {showAlert && (
                <Alert variant='danger' className="alert--small mt-3">
                  Este correo electrónico no existe.
                </Alert>
              )}
            </div>
          
            <div className="form-row form--last">
              <button type="submit" className="btn btn--type1">Enviar correo</button>
            </div>
            <div className="form-row form--last">
              <a href="/iniciar-sesion" className="btn--simple">Ir a inicio de sesión</a>
            </div>
          </form>
        </div>
    </>
  );
}

export default ForgotPasswordComponent;