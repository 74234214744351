import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import LoginComponent from '../../../components/Auth/Login/login.component';
import cards from '../../../utils/cardsGeneral.json';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';

const LoginScreen: React.FC = () => {
  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="hero-intro__col-top col-lg-7 col-12">
              <h1 className="text-52">Te acompañamos en el cumplimiento de la ley de aborto <span className="text-wine400">en Chile</span></h1>
             
            </div>
            <aside className="col-lg-5 col-12">
              <h2 className="text-40">Hola, bienvenida</h2>
              <LoginComponent />
            </aside>
          </div>
        </div>
      </section>
      <CardWrapperComponent
        title="La ley te protege y nosotras te respaldamos"
        cards={cards}
      />
     
      <FooterShared />
    </>
  );
};

export default LoginScreen;