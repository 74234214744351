import React, {useEffect, useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';

const GestoraStep4Component = () => {
  const [formularioValido, setFormularioValido] = useState(false);

  const navigate = useNavigate();

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();
  
    camposRequeridos.forEach(campo => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
        console.log(campo)
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto)
        }
      }
    });
  
    gruposRadio.forEach(nombre => {
      const seleccionado = document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado)
    });
  
    setFormularioValido(todosCompletos);
  };


  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/gestora-paso-5');
  };

  useEffect(() => {
    verifyFields();
  }, []);



  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
        <div className="form-row">
            <label className="form-label label--icon">
            ¿Cuáles crees que son tus características o habilidades personales, que te hacen idónea para cumplir el rol de gestora? <small className='required'>*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    ¿Cuáles crees que son tus características o habilidades personales, que te hacen idónea para cumplir el rol de gestora?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            
            <textarea className="form-control requiredField" id="other" placeholder="Ingresa de 3 a 5 habilidades que te destaquen" rows={3}></textarea>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Cuánto es tu tiempo disponible semanal para colaborar en OLA?  <small className="required">*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                    ¿Cuánto es tu tiempo disponible semanal para colaborar en OLA?  

                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="colaborar" id="colaborar1" value="1 a 2 hrs"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="colaborar1">
                  1 a 2 hrs
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="colaborar" id="colaborar2" value="2 a 4 hrs"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="colaborar2">
                  2 a 4 hrs
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="colaborar" id="colaborar3" value="4 a 6 hrs"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="colaborar3">
                  4 a 6 hrs
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="colaborar" id="colaborar4" value="6 a 8 hrs"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="colaborar4">
                  6 a 8 hrs
                </label>
              </div>
             
              
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Tienes disponibilidad inmediata para participar como gestora? <small className="required">*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                   ¿Tienes disponibilidad inmediata para participar como gestora?

                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="disponibilidad" id="disponibilidadSi" value="si"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="disponibilidadSi">
                  Sí
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="disponibilidad" id="disponibilidadNo" value="no"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="disponibilidadNo">
                  No
                </label>
              </div>
              
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Aceptas recibir la capacitación (presencial y/o en línea) necesaria para llevar  a cabo tu labor?<small className="required">*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                   ¿Aceptas recibir la capacitación (presencial y/o en línea) necesaria para llevar a cabo tu labor?

                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="capacitacion" id="capacitacionSi" value="si"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="capacitacionSi">
                  Sí
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="capacitacion" id="capacitacionNo" value="no"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="capacitacionNo">
                  No
                </label>
              </div>
              
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Aceptas seguir todos los lineamientos de seguridad y privacidad de los datos  de todas las mujeres que conozcas y llegues a conocer en OLA?<small className="required">*</small>
            <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip >
                  ¿Aceptas seguir todos los lineamientos de seguridad y privacidad de los datos  de todas las mujeres que conozcas y llegues a conocer en OLA?
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="privacidad" id="privacidadSi" value="si"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="privacidadSi">
                  Sí
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="privacidad" id="privacidadNo" value="no"  onChange={verifyFields}/>
                <label className="form-check-label" htmlFor="privacidadNo">
                  No
                </label>
              </div>
              
            </div>
          </div>
  
        
  
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
             Siguiente
            </button>
          </div>
       
        </form>
      </div>
    </>
  );
};

export default GestoraStep4Component;
