import React from 'react';

interface Props {
  imageUrl?: string;
  title?: string;
  subtitle?: string | null;
  content?: string;
  URL?: string | null;
  URLText?: string | null;
  ExternalLink?: string | null;
}

const CardImageComponent: React.FC<Props> = ({ imageUrl, title, subtitle, content, URL, URLText, ExternalLink }) => {
  return (
    <div className="card-row-item">
      {imageUrl ? (
        <figure className="card-row-item__image">
          <img src={imageUrl} alt={title} />
        </figure>
      ) : null}
      <div className="card-row-item__title">
        {title ? <h3 className="text-24 mb-1 pb-0">{title}</h3> : null}
        {subtitle !== null ? <p><strong>{subtitle}</strong></p> : null}
      </div>
      {content ? (
        <div className="card-row-item__text">
          <p>{content}</p>
        </div>
      ) : null}
      {(URL || URLText) ? (
        <a href={URL || ''} target="_blank" rel="noreferrer" className="btn btn--type2 btn--100">
          {URLText || ''}
        </a>
      ) : null}
      {ExternalLink ? (
        <a href={ExternalLink || ''} target="_blank" rel="noreferrer">
          {ExternalLink || ''}
        </a>
      ) : null}
    </div>
  );
};

export default CardImageComponent;
