import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import UpdatePasswordComponent from '../../../components/Auth/UpdatePassword/updatePassword.component';

const UpdatePasswordScreen: React.FC = () => {
  // Your component logic goes here

  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="hero-intro__col-top col-lg-5 col-12">
              <h1 className="text-40 mb-3">Actualizar<br/> tu contraseña</h1>
              <p className="text-20">Crea una nueva contraseña, que recuerdes fácil y sólo tú conozcas.</p>
              <UpdatePasswordComponent />
            </div>
            <aside className="col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
        </div>
      
      </section>
      <FooterShared />
    </>
  );
};

export default UpdatePasswordScreen;