import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScollToTop/scrollToTop.component';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LoginScreen from './pages/Auth/Login/login.screen';
import ForgotPasswordScreen from './pages/Auth/ForgotPassword/forgotPassword.screen';
import UpdatePasswordScreen from './pages/Auth/UpdatePassword/updatePassword.screen';
import GestoraComunitariaIndexScreen from './pages/Auth/GestoraComunitaria/index.screen';
import GestoraComunitariaDemograficosScreen from './pages/Auth/GestoraComunitaria/demograficos.screen';
import GestoraComunitariaAntecedentesScreen from './pages/Auth/GestoraComunitaria/antecedentes.screen';
import GestoraComunitariaCompromisoScreen from './pages/Auth/GestoraComunitaria/compromiso.screen';
import GestoraComunitariaMasScreen from './pages/Auth/GestoraComunitaria/mas.screen';
import GestoraComunitariaProcessScreen from './pages/Auth/GestoraComunitaria/process.screen';

function App() {
  return (
    <main className="app-main">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/recuperar-contrasena" element={<ForgotPasswordScreen />} />
            <Route path="/actualizar-contrasena" element={<UpdatePasswordScreen />} />
            <Route path="/gestora-paso-1" element={<GestoraComunitariaIndexScreen />} />
            <Route path="/gestora-paso-2" element={<GestoraComunitariaDemograficosScreen />} />
            <Route path="/gestora-paso-3" element={<GestoraComunitariaAntecedentesScreen />} />
            <Route path="/gestora-paso-4" element={<GestoraComunitariaCompromisoScreen />} />
            <Route path="/gestora-paso-5" element={<GestoraComunitariaMasScreen />} />
            <Route path="/gestora-proceso" element={<GestoraComunitariaProcessScreen />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </main>
  );
}

export default App;
