import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import Step6Component from '../../../components/Auth/Gestora/gestoraStep6.component';

interface Props {
  // Define your component props here
}

const GestoraComunitariaProcessScreen: React.FC<Props> = () => {
  const steps = [
    { title: "Recibimos tu perfil", description: "En proceso", status: "status--process", completed: false, color: "pink400" },
    { title: "Agendamos una entrevista", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Conoce al equipo OLA", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Recibe tu acceso a OLA", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Empieza a hacer la diferencia", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - green350
    //-- In progress status--success - pink400
    //-- In progress status neutral '' - gray600
  ];
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-md-8 col-12">
                <h1 className="text-64 mb-3">¡Gracias por registrarte
                como gestora!</h1>
                <p className="text-32 mb-0"><strong>Revisaremos cuidadosamente tu solicitud
                y nos pondremos en contacto contigo</strong></p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
              </div>
              <Step6Component />
            </div>
           
           
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default GestoraComunitariaProcessScreen;