import React, {useState} from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import GestoraStep5Component from '../../../components/Auth/Gestora/gestoraStep5.component';

const GestoraComunitariaMasScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeStep, setActiveStep] = useState(5); // Estado para el paso actual
  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="module-bottom40 col-12">
              <StepperComponent activeStep={activeStep} />
            </div>
            <div className="col-12 mb-4">
              <a href="/gestora-paso-4" className="btn--icon btn--simple btn--simple--small"><i className="icon fa-solid fa-arrow-left"></i> Regresar</a>
            </div>
            <div className="col-lg-5 col-12">
              <h1>Queremos conocerte mas</h1>
              <article className="text-20 col-12 col-reset">
                <p>
                  Para formar parte de nosotras como gestora comunitaria, necesito hacerte algunas preguntas.
                </p>
                <p className="text-green350">
                  Recuerda que este proceso, así como tus datos están protegidos.
                </p>
              </article>
              <GestoraStep5Component />
            </div>
          </div>
        </div>
      </section>
     
      <FooterShared />
    </>
  );
};

export default GestoraComunitariaMasScreen;