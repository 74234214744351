import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import ForgotPasswordComponent from '../../../components/Auth/ForgotPassword/forgotPassword.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGeneral.json';

const ForgotPasswordScreen: React.FC = () => {
  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="hero-intro__col-top col-lg-5 col-12">
              <h1 className="text-40 mb-3">¿Olvidaste tu<br/> contraseña?</h1>
              <p className="text-20">No te preocupes, ingresa el correo electrónico con el que te registraste, y recibirías un correo de confirmación para actualizar tu contraseña.</p>
              <ForgotPasswordComponent />
            </div>
            <aside className="col-lg-7 col-12 d-flex justify-content-center">
              <figure className="image-350">
                <img src="/assets/images/illustration/ilustration1.png" alt="Te acompañamos" />
              </figure>
            </aside>
          </div>
        </div>
      
      </section>
      <CardWrapperComponent title="La ley te protege y nosotras te respaldamos" cards={cards} />
      <FooterShared />
    </>
  );
};

export default ForgotPasswordScreen;